import React, { useEffect } from "react";
import {
  MainButton,
  WebAppProvider,
  useShowPopup,
} from "@vkruglikov/react-telegram-web-app";
import { Helmet } from "react-helmet";

function App() {
  const showPopup = useShowPopup();

  const handleClick = () =>
    showPopup({
      message: "Hello, I am popup",
    });

  return (
    <WebAppProvider options={{ smoothButtonsTransition: false }}>
      <p>hola</p>
      <MainButton text={"SECOND BUTTON"} progress={true} key="1" />
      {/** Use components inside provider */}
      <MainButton text="SHOW POPUP" onClick={handleClick} />
    </WebAppProvider>
  );
}

export default App;
